(function (defaults) {
	'use strict';

	if (defaults.DBK_ENVIRONMENT !== 'staging') {
		return;
	}

	defaults.DBK_BASEURL = 'https://staging.dayback.com/';

	// DayBack API
	defaults.DBK_API_BASEURL = `${defaults.DBK_BASEURL}api/`;
	defaults.DBK_JOURNEY_URL = `${defaults.DBK_API_BASEURL}journey`;
	defaults.DBK_TRIAL_URL = `${defaults.DBK_API_BASEURL}trial`;
	defaults.DBK_SUBSCRIPTION_URL = `${defaults.DBK_API_BASEURL}subscription`;
	defaults.DBK_TOKENS_URL = `${defaults.DBK_API_BASEURL}tokens`;
	defaults.DBK_STATUS_URL = `${defaults.DBK_API_BASEURL}webhook/status`;
})(window._CONFIG);
